<template>
  <div
    class="label-new overline"
    v-text="'NEW'"
  />
</template>

<style lang="scss" scoped>
.label-new {
  padding: 2px 8px;
  color: $light;
  background: $primary;
  border-radius: 14px;
}
</style>
