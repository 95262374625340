<template>
  <div class="page">
    We don't support Facebook/Messenger browser. Please open form link in any other browser instead 🙏
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.page {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: white;
}
</style>
